html {
    &.-modal {
        overflow-x: hidden;

        .map-tooltip {
            display: none !important;
        }
    }

    &.-map {
        overflow-x: hidden;
        background: linear-gradient( var(--color--map-fg) 80%, var(--color--primary-dark) 100%);

        &.-map-zoom {
            .maplibregl-canvas {
                pointer-events: none;
                user-select: none;
            }

            .top-countries {
                right: -25rem;
                opacity: 0;
                transition-delay: 0s;
            }

            .filter {
                left: -25rem;
                opacity: 0;
                transition-delay: 0s;
            }

            .month {
                transform: translateY(150%);
                opacity: 0;
                transition-delay: 0s;

                @media screen and (min-width: 1024px) {
                    transform: translate(-50%, 150%);
                }
            }
        }
    }

    &.mercator {
        .hp__map {
            min-height: 100vh;

            @media screen and (max-width: 1023px) {
                display: flex;
                align-items: center;
            }
        }

        #map {
            width: 100%;
            left: 0;
            height: 100%;
            border-radius: 1rem;
            transition: border-radius var(--transition-slow);

            @media screen and (max-width: 1023px) {
                width: calc(100% - 2rem);
                left: 1rem;
                height: calc(100vh - 4rem);
                max-height: 100dvw;
            }

            @media screen and (min-width: 1024px) {
                width: calc(100% - 10rem);
                height: calc(100vh - 10rem);
                top: 5rem;
                left: 5rem;
            }
        }

        &.-map-zoom {
            #map {
                border-radius: 8rem;

                @media screen and (min-width: 1024px) {
                    border-radius: 50%;
                }
            }
        }

        .month {
            background-color: var(--color--white);
            box-shadow: var(--shadow-small);
            bottom: 1rem;

            .noUi-target {
                background: var(--color--grey-light);
            }

            .noUi-marker-horizontal.noUi-marker {
                background-color: var(--color--grey-medium) !important;
            }

            .month__arrow {
                background-color: var(--color--grey-xlight);
            }

            .noUi-tooltip {
                background: var(--color--primary);
                color: var(--color--white);
            }

            @media screen and (min-width: 1024px) {
                bottom: 2rem;
            }
        }
    }

    &.-mobile-top-countries,
    &.-mobile-filter {
        @media screen and (max-width: 1023px) {
            .header {
                top: calc((var(--header-height) * -1) - 2rem);
            }

            .month {
                z-index: 9;
            }
        }
    }

    &.-map-loader {
        #map {
            opacity: 0;
        }

        .hp__hero__btn {
            opacity: 0.4;
            pointer-events: none;
            user-select: none;
        }
    }
}

#map {
    position: relative;
    z-index: 0;
    background: transparent;
    height: 150vh;
    transition: opacity var(--transition-slow);

    @media screen and (height < 780px) {
        &::before {
            top: 6vh;
        }
    }

    @media screen and (height < 660px) {
        &::before {
            display: none;
        }
    }
}

.filter-container {
    position: fixed;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;

    @media screen and (max-width: 1023px) {
        top: unset;
        bottom: 8rem;
        transform: unset;
        left: 0.75rem;
    }
}

.filter-select {

    .ss-main {
        border: none;
        border-radius: 0.75rem;
        padding: 0;

        .ss-arrow {
            margin-right: 2rem;

            @media screen and (max-width: 1023px) {
                margin: 0;
                position: relative;
                min-width: 10px;
                width: 10px;
                right: 8px;
            }
        }

        .ss-single {
            padding: 1rem 0.5rem !important;

            p {
                font-weight: 600;
                font-size: 1rem;
                color: var(--color--primary-dark);
                
                @media screen and (max-width: 1023px) {
                    display: none;
                }
            }

            @media screen and (min-width: 1024px) {
                padding: 0.75rem 1.5rem !important;
            }
        }

        &:focus {
            box-shadow: none !important;
        }

        &:focus-visible {
            outline: 0.125rem solid var(--color--primary);
        }

        @media screen and (max-width: 1023px) {
            height: 3rem;
            width: 3.5rem;
            border-radius: 0.5rem;
            align-items: center;
        }

        @media screen and (min-width: 1024px) {
            min-height: 2rem;
            width: 100%;
            min-width: 11rem;
        }
    }

    @media screen and (max-width: 1023px) {
        position: relative;
        margin-left: 0;
        top: -1rem;
        left: -1rem;
        width: calc(100% + 2rem);
        z-index: 25;
        box-shadow: var(--shadow);
    }

    @media screen and (min-width: 1024px) {
        width: calc(100% + 3rem);
        margin-top: -0.75rem;
        position: relative;
        left: -1.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 0.0625rem solid var(--color--grey-light);
    }
}

.ss-content .ss-list .ss-option,
.ss-single {
    display: flex !important;
    align-items: center;
    gap: 0.75rem !important;
    margin: 0 !important;
    padding: 0.75rem !important;
    transition: var(--transition);

    i {
        font-size: 1.5rem;
        color: var(--color--grey-dark);

        &.icon-framer {
            color: var(--color--global-90);
        }

        &.icon-beach {
            color: var(--color--beach-80);
        }

        &.icon-surf {
            color: var(--color--surf-80);
        }

        &.icon-ski {
            color: var(--color--ski-80);
        }

        &.icon-hiking {
            color: var(--color--hiking-80);
        }

        @media screen and (max-width: 1023px) {
            font-size: 1.25rem;
        }
    }

    p {
        margin: 0;

        @media screen and (max-width: 1023px) {
            display: none;
        }
    }
}

.-mobile-top-countries {
    .ss-content .ss-list .ss-option,
    .ss-single {
        p {
            @media screen and (max-width: 1023px) {
                display: block;
            }
        }
    }
}

html:not(.-mobile-top-countries) {
    .ss-content.ss-open-below {
        @media screen and (max-width: 1023px) {
            width: 3.5rem !important;
        }
    }

    .ss-content .ss-list .ss-option,
    .ss-single {
        @media screen and (max-width: 1023px) {
            justify-content: center;
        }
    }
}

.ss-content.ss-open-below {
    box-shadow: var(--shadow-small);
    border: none;
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
}

.ss-content .ss-list .ss-option {
    padding: 1rem 0.75rem !important;
    font-weight: 600;
    &:hover,
    &:focus-visible {
        color: var(--color--primary-dark) !important;
        background-color: var(--color--foreground) !important;
    }

    &.ss-highlighted {
        background-color: var(--color--foreground) !important;
        outline: 0.125rem solid var(--color--primary);
    }

    &:not(.ss-disabled).ss-selected {
        color: var(--color--primary-dark) !important;
        background-color: var(--color--foreground) !important;
    }

    @media screen and (max-width: 1023px) {
        padding: 1rem 1.125rem !important;
    }
}

.month {
    position: absolute;
    padding: 1rem;
    left: 1rem;
    border-radius: 0.5rem;
    display: grid;
    width: calc(100% - 2rem);
    grid-template-columns: 2rem minmax(0, 1fr) 2rem;
    align-items: center;
    gap: 2rem;
    bottom: calc(25vh + 1rem);
    transition: transform var(--transition-slow), opacity var(--transition-slow);
    transition-delay: 0.8s;
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.2);

    .month__slider {
        width: 100%;
        padding-top: 0.75rem;

        .noUi-origin {
            transition: var(--transition);
        }

        .noUi-base {
            height: 3rem;
            transform: translateY(-50%);
            cursor: pointer;
        }

        .noUi-handle {
            top: 12px;
        }

        .noUi-horizontal {
            height: 0.25rem;
        }

        .noUi-target {
            background-color: rgba(255, 255, 255, 0.4);
        }

        .noUi-pips-horizontal {
            padding: 0;
            height: 0.375rem;
            top: -0.75rem;
            left: 2px;
            width: calc(100% - 4px);
            opacity: 0.2;

            .noUi-marker-horizontal.noUi-marker {
                height: 100%;
                border-radius: 2px;
                background-color: var(--color--white);
            }
        }

        .noUi-tooltip {
            padding: 0.5rem 0.75rem;
            font-size: 0.875rem;
            font-weight: 600;
            text-transform: capitalize;
            border: none;
            border-radius: 0.25rem;
            background-color: var(--color--white);
            color: var(--color--primary);
            bottom: 130%;
        }
    }

    .month__arrow {
        border: none;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color--white);
        color: var(--color--grey-dark);
        opacity: 0.7;
        border-radius: 0.25rem;
        cursor: pointer;
        transition: var(--transition-slow);

        &:hover,
        &:focus-visible {
            opacity: 1;
            color: var(--color--primary);
        }
    }

    @media screen and (min-width: 1024px) {
        left: 50%;
        transform: translateX(-50%);
        width: clamp(20rem, calc(100dvw - 44rem), 40rem);
        bottom: calc(25vh + 2rem);
    }
}

.top-countries {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 5;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: var(--shadow-small);
    background-color: var(--color--white);
    transition: right var(--transition-slow);

    .top-countries__header {
        display: none;

        @media screen and (min-width: 1024px) {
            display: flex;
            flex-direction: column;
            gap: 0.375rem;
            margin-top: 0.5rem;
        }
    }

    .top-countries__items {
        display: none;
        margin: 0;
        padding: 0 0.25rem 0 0;
        list-style: none;
        flex-direction: column;
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--color--grey-medium) var(--color--white);
        width: calc(100% + 0.75rem);

        li {
            .top-link {
                position: relative;
                display: grid;
                width: 100%;
                grid-template-columns: 0.25rem auto minmax(0, 1fr) auto;
                align-items: center;
                gap: 1rem;
                padding: 0.0625rem 0.5rem 0 0;
                color: var(--color--grey-dark);
                height: 2.25rem;
                background-color: transparent;
                border: none;
                text-align: left;
                cursor: pointer;
                transition: var(--transition);
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
                font-size: 0.875rem;
                overflow: hidden;
                text-decoration: none;

                div {
                    height: 100%;
                    background-color: var(--color--primary);
                }

                p {
                    transition: var(--transition);
                }

                p:nth-child(2) {
                    font-family: sans-serif;
                    font-weight: 600;
                    font-size: 0.75rem;
                }

                p:nth-child(3) {
                    font-weight: 600;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                p:last-child {
                    font-family: sans-serif;
                    font-size: 0.75rem;
                    color: var(--color--grey);
                }

                &::before {
                    background-color: var(--color--primary);
                    opacity: 0;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transition: var(--transition);
                }

                &:hover,
                &:focus-visible {

                    &::before {
                        opacity: 0.1;
                    }

                    p {
                        color: var(--color--primary);
                    }
                }
            }

            &:first-child {
                .top-link {
                    div:first-child {
                        border-top-left-radius: 0.25rem;
                        border-top-right-radius: 0.25rem;
                    }
                }
            }

            &:last-child {
                .top-link {
                    div:first-child {
                        border-bottom-left-radius: 0.25rem;
                        border-bottom-right-radius: 0.25rem;
                    }
                }
            }
        }

        p {
            margin: 0;
        }

        .empty {
            display: flex;
            align-items: center;
            gap: 1rem;
            padding: 1rem;
            border-radius: 0.5rem;
            background-color: rgba(var(--color--primary-rgb), 0.15);
            p {
                font-size: 0.75rem;
                line-height: 1.2rem;
                color: var(--color--primary);
                font-weight: 500;
            }

            i {
                font-size: 1.5rem;
                color: var(--color--primary);
            }
        }

        &::-webkit-scrollbar {
            width: 0.5rem;
            border-radius: 0.5rem;
        }

        &::-webkit-scrollbar-track {
            background: var(--color--white);
            border-radius: 0.5rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--color--grey-medium);
            border-radius: 0.5rem;
            border: 0;
        }

        @media screen and (min-width: 1024px) {
            display: flex;
        }
    }

    .top-countries__legend {
        position: relative;

        p {
            margin: 0;
            display: flex;

            span {
                display: flex;
                flex-direction: column;
                gap: 0.375rem;
                flex: 1;
                font-size: 0.625rem;
                color: var(--color--grey);

                &::before {
                    content: '';
                    width: 100%;
                    height: 0.375rem;
                    background-color: var(--color--primary);
                }
                @media screen and (max-width: 1023px) {
                    flex-direction: row;

                    &::before {
                        height: 0.8125rem;
                        width: 0.25rem;
                    }

                    &:first-child {
                        &::before {
                            border-bottom-left-radius: 0.25rem;
                            border-bottom-right-radius: 0.25rem;
                        }
                    }

                    &:last-child {
                        &::before {
                            border-top-left-radius: 0.25rem;
                            border-top-right-radius: 0.25rem;
                        }
                    }
                }

                @media screen and (min-width: 1024px) {
                    &:first-child {
                        &::before {
                            border-top-left-radius: 0.25rem;
                            border-bottom-left-radius: 0.25rem;
                        }
                    }

                    &:last-child {
                        &::before {
                            border-top-right-radius: 0.25rem;
                            border-bottom-right-radius: 0.25rem;
                        }
                    }
                }
            }

            @media screen and (max-width: 1023px) {
                display: flex;
                flex-direction: column-reverse;
            }
        }

        @media screen and (min-width: 1024px) {
            display: flex;
            flex-direction: column-reverse;
            gap: 0.375rem;
        }
    }

    .top-countries__legend__help {
        position: absolute;
        top: 0;
        right: -0.75rem;
        display: grid;
        height: 100%;
        grid-template-rows: repeat(3, minmax(0, 1fr));
        align-items: center;
        background-color: var(--color--white);
        padding: 0 0.25rem;

        i {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            font-size: 1.25rem;
            color: var(--color--global-90);

            &.icon-frown {
                color: var(--color--global-60);
            }

            &.icon-meh {
                color: var(--color--global-75);
            }
        }

        @media screen and (min-width: 1024px) {
            display: flex;
            flex-direction: row-reverse;
            position: relative;
            top: unset;
            right: unset;
            width: 100%;
        }
    }

    .top-countries__title {
        margin: 0;
        font-size: 1rem;

        @media screen and (max-width: 1023px) {
            margin-bottom: 0.5rem;
        }
    }

    .top-countries__text {
        font-size: 0.625rem;
        margin: 0;
        color: var(--color--grey);
    }
    
    .top-countries__mobile__button {
        display: none;

        @media screen and (max-width: 1023px) {
            display: block;
            position: relative;
            margin-left: 0;
            bottom: -1rem;
            left: -1rem;
            width: calc(100% + 2rem);
            height: 3rem;
            z-index: 25;
            box-shadow: var(--shadow-small);
            border: none;
            background-color: transparent;

            i {
                font-size: 1.25rem;
            }
        }
    }

    &:has(div.empty) {
        @media screen and (min-width: 1024px) {
            .top-countries__legend {
                display: none;
            }
        }
    }

    &.-mobile-active {
        @media screen and (max-width: 1023px) {
            height: calc(100vh - 8rem);
            top: calc(50% - 3rem);
            width: clamp(15rem, calc(100% - 1rem), 18rem);
            z-index: 8;

            .filter-select {
                .ss-main {
                    width: calc(100% - 3.5rem);
                    height: 3.5rem;

                    .ss-arrow {
                        display: block;
                    }
                }
            }

            .ss-content .ss-list .ss-option, .ss-single {
                p {
                    display: block;
                }
            }

            .top-countries__mobile__button {
                position: absolute;
                top: 0;
                right: 0;
                bottom: unset;
                left: unset;
                transform: unset;
                width: 3.5rem;
                height: 3.5rem;

                .icon::before {
                    content: "\e996";
                }
            }
            
            .top-countries__header,
            .top-countries__items {
                display: block;
                margin-bottom: 1.5rem;
            }

            .top-countries__legend {
                display: flex;
                flex-direction: column-reverse;
                gap: 0.75rem;
                padding: 0 0.5rem;

                p {
                    flex-direction: row;

                    span {
                        flex-direction: column;

                        &:first-child {
                            &::before {
                                border-radius: 0.25rem 0 0 0.25rem;
                            }
                        }

                        &:last-child {
                            &::before {
                                border-radius: 0 0.25rem 0.25rem 0;
                            }
                        }

                        &::before {
                            width: 100%;
                            height: 0.375rem;
                        }
                    }
                }

                .top-countries__legend__help {
                    position: relative;
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                    direction: rtl;
                    grid-template-rows: auto;
                }
            }
        }
    }

    &:not(.-mobile-active) {
        @media screen and (max-width: 1023px) {
            .top-countries__legend {
                left: -0.125rem;
            }

            .top-countries__legend p span {
                font-size: 0;
            }

            .top-countries__legend__help {
                right: -0.75rem;
            }
        }
    }

    &.type-surf {
        .top-countries__legend__help {
            i {
                color: var(--color--surf-90);

                &.icon-frown {
                    color: var(--color--surf-60);
                }

                &.icon-meh {
                    color: var(--color--surf-75);
                }
            }
        }
    }

    &.type-beach {
        .top-countries__legend__help {
            i {
                color: var(--color--beach-90);

                &.icon-frown {
                    color: var(--color--beach-60);
                }

                &.icon-meh {
                    color: var(--color--beach-75);
                }
            }
        }
    }

    &.type-ski {
        .top-countries__legend__help {
            i {
                color: var(--color--ski-90);

                &.icon-frown {
                    color: var(--color--ski-60);
                }

                &.icon-meh {
                    color: var(--color--ski-75);
                }
            }
        }
    }

    &.type-hiking {
        .top-countries__legend__help {
            i {
                color: var(--color--hiking-90);

                &.icon-frown {
                    color: var(--color--hiking-60);
                }

                &.icon-meh {
                    color: var(--color--hiking-75);
                }
            }
        }
    }

    @media screen and (max-width: 1023px) {
        right: 1rem;
        top: calc(50% - 3rem);
        transform: translateY(-50%);
        width: 3.5rem;
    }

    @media screen and (min-width: 1024px) {
        position: absolute;
        top: 50%;
        right: 2rem;
        border-radius: 0.5rem;
        padding: 1.5rem;
        gap: 1rem;
        width: 18rem;
        transform: translateY(-50%);
        height: calc(100vh - var(--header-height) - 4rem);
        max-height: 38rem;
        transition-duration: var(--transition-slow);
        transition-delay: 0.6s;
        box-shadow: none;
        border: 0.0625rem solid var(--color--grey-light);
    }
}

.maplibregl-popup .maplibregl-popup-content {
    background: var(--color--white) !important;
    border-radius: 0.5rem !important;
    box-shadow: 0 0.15rem 0.25rem rgba(0, 0, 0, 0.1) !important;
    padding: 0.5rem 0.75rem !important;
    pointer-events: none !important;
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
    }

    .map-tooltip__title {
        font-weight: bold;
        font-size: 0.875rem;
        color: var(--color--primary-dark);
    }

    .map-tooltip__score {
        font-size: 0.625rem;
        color: var(--color--grey-dark);

        span {
            font-size: 0.75rem;
            font-weight: bold;
            margin-left: 0.125rem;
        }
    }
}

.maplibregl-popup .maplibregl-popup-tip {
    display: none !important;
}

.filter-mobile {
    position: absolute;
    top: calc(50% + 5rem);
    box-shadow: var(--shadow-small);
    right: 1rem;
    z-index: 6;

    .filter-mobile__btn {
        background-color: var(--color--white);
        width: 3.5rem;
        height: 3.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        border: none;

        i {
            font-size: 1.25rem;
        }
    }

    .filter__indicator {
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        font-size: 0.75rem;
        font-weight: 600;
        color: var(--color--white);
        background-color: var(--color--primary);
        z-index: 2;
        outline: 0.375rem solid rgba(var(--color--primary-rgb), 0.2);
        transition: var(--transition);
        border: none;
        cursor: pointer;

        &:empty {
            display: none;
        }

        &::before {
            content: "\e996";
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'whentoexplore' !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 1.25rem;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: var(--color--primary);
            opacity: 0;
            visibility: hidden;
            border-radius: 50%;
            transition: var(--transition);
        }
    }

    @media screen and (min-width: 1024px) {
        display: none;
    }
}

.filter {
    display: grid;
    grid-template-rows: auto 1fr;
    position: absolute;
    top: 50%;
    border-radius: 0.5rem;
    transform: translateY(-50%);
    transition-duration: var(--transition-slow);
    transition-delay: 0.4s;
    border: 0.0625rem solid var(--color--grey-light);
    background-color: var(--color--white);
    overflow: hidden;
    z-index: 8;

    .filter__items {
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .filter__item {
        position: relative;
        display: flex;
        flex-direction: column;
        border-bottom: 0.0625rem solid var(--color--grey-light);
        transition: var(--transition);
        
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--color--primary);
            opacity: 0;
            z-index: -1;
            transition: var(--transition);
        }

        &.-active {
            .filter__item__btn {
                span,
                i:nth-child(1) {
                    color: var(--color--primary);
                }
            }

            .filter__item__options {
                display: flex;
            }

            &.-range {
                .filter__item__options {
                    display: grid;
                    grid-template-columns: auto minmax(0, 1fr) auto;
                    align-items: center;
                    gap: 0.5rem;
                }
            }
        }

        &.-weather {
            --color--primary: var(--color--weather);
        }

        &.-sea {
            --color--primary: var(--color--surf-80);
        }

        &.-rain {
            --color--primary: var(--color--rain);
        }

        &.-price {
            --color--primary: var(--color--hiking-80);
        }

        &.-tourism {
            --color--primary: var(--color--beach-75);
        }

        &.-filter {
            span,
            i:nth-child(1) {
                color: var(--color--primary);
            }
            &::before {
                opacity: 0.1;
            }
        }
        
        &.-gpi {
            --color--primary: var(--color--hiking-75);

            .filter__item__options {
                grid-template-columns: 1fr !important;
                gap: 1.25rem !important;
                padding-bottom: 3rem;

                #gpi-slide {
                    margin: 0 0.5rem;
                    grid-column: 1;

                    .noUi-marker-horizontal.noUi-marker {
                        width: 0.0625rem;
                        background-color: var(--color--grey-light);
                    }

                    .noUi-value {
                        font-size: 0.625rem;
                        margin-top: 0;
                        background: var(--color--white);
                        padding: 0.25rem;
                        color: var(--color--grey-medium);
                        font-family: sans-serif;

                        &.noUi-value-large {
                            font-size: 0.75rem;
                        }

                        &[data-value="1"] {
                            color: var(--color--hiking-80);
                            font-weight: 600;
                        }

                        &[data-value="2"] {
                            color: var(--color--global-75);
                            font-weight: 600;
                        }

                        &[data-value="3"] {
                            color: var(--color--global-60);
                            font-weight: 600;
                        }

                        &[data-value="4"] {
                            color: var(--color--global-0);
                            font-weight: 600;
                        }
                    }

                    &.-slide-2 {
                        .noUi-connect {
                            background: linear-gradient(90deg, var(--color--hiking-75) 0%, var(--color--hiking-60) 100%);
                        }
                    }
                    &.-slide-2-5 {
                        .noUi-connect {
                            background: linear-gradient(90deg, var(--color--hiking-75) 0%, var(--color--beach-60) 100%);
                        }
                    }
                    &.-slide-3 {
                        .noUi-connect {
                            background: linear-gradient(90deg, var(--color--hiking-75) 0%, var(--color--beach-60) 70%, var(--color--beach-75) 100%);
                        }
                    }
                    &.-slide-3-5 {
                        .noUi-connect {
                            background: linear-gradient(90deg, var(--color--hiking-75) 0%, var(--color--beach-60) 50%, var(--color--beach-75) 70%, var(--color--global-60) 100%);
                        }
                    }
                    &.-slide-4 {
                        .noUi-connect {
                            background: linear-gradient(90deg, var(--color--hiking-75) 0%, var(--color--beach-60) 40%, var(--color--beach-75) 60%, var(--color--global-60) 80%, var(--color--global-0) 100%);
                        }
                    }
                }

                .filter__item__text {
                    font-size: 0.75rem;
                    line-height: 1.5;
                    color: var(--color--grey);
                    font-weight: 300;
                    opacity: 0.8;
                }
            }
        }

        &.-region {
            --color--primary: var(--color--ski-85);
        }
    }

    .filter__item__btn {
        width: 100%;
        display: grid;
        grid-template-columns: minmax(0, 1.5rem) minmax(0, 1fr) minmax(0, 1.5rem);
        gap: 0.5rem;
        align-items: center;
        text-align: left;
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 1.25rem 1.5rem;

        span {
            transition: var(--transition);
            font-size: 0.875rem;
            font-weight: 500;
        }

        i {
            font-size: 1rem;
            transition: var(--transition);

            &:nth-child(1) {
                font-size: 1.25rem;
            }
        }

        &:hover,
        &:focus-visible {
            span,
            i {
                color: var(--color--primary);
            }
        }
    }
    
    .filter__item__options {
        display: none;
        flex-wrap: wrap;
        gap: 0.25rem;
        margin: 0 1rem 1rem;
        padding: 0.75rem;
        background: var(--color--white);
        border-radius: 0.5rem;

        .noUi-target {
            grid-column: span 3;
            margin: 0.5rem 1rem 1rem;
        }

        select {
            font-size: 0.75rem;
            padding: 0.5rem;
            border-radius: 0.25rem;
            border: 0.0625rem solid var(--color--grey-light);
            background-color: var(--color--white);

            & + p {
                font-size: 0.75rem;
                color: var(--color--grey);
                font-weight: 400;
            }
        }

        p {
            margin: 0;
        }

        .filter__item__options__btn {
            display: flex;
            align-items: center;
            gap: 0.375rem;
            position: relative;
            border: none;
            background-color: var(--color--grey-xlight);
            border-radius: 0.25rem;
            padding: 0.375rem 0.5rem;
            cursor: pointer;
            overflow: hidden;
            font-size: 0.875rem;
            color: var(--color--grey);
            transition: var(--transition);
            opacity: 0.7;
            font-weight: 500;

            i {
                color: var(--color--grey);
                transition: var(--transition);
                font-size: 1rem;
            }

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: var(--color--primary);
                opacity: 0;
                transition: var(--transition);
            }

            &:hover,
            &:focus-visible {
                color: var(--color--primary);

                i {
                    color: var(--color--primary);
                }
            }

            &.-low {
                --color--primary: var(--color--hiking-75);
            }

            &.-medium {
                --color--primary: var(--color--beach-75);
            }

            &.-high {
                --color--primary: var(--color--global-60);
            }

            &.-active {
                color: var(--color--primary);
                background-color: var(--color--white);
                opacity: 1;

                i {
                    color: var(--color--primary);
                }

                &::before {
                    opacity: 0.2;
                }
            }
        }
    }

    .filter__header {
        padding: 1.25rem 1.5rem;
        border-bottom: 0.0625rem solid var(--color--grey-light);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        .filter__header__title {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin: 0;

            .filter__indicator {
                font-family: sans-serif;
                width: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 1.5rem;
                border-radius: 50%;
                font-size: 0.625rem;
                font-weight: 600;
                color: var(--color--white);
                background-color: var(--color--primary);
                z-index: 2;
                outline: 0.37rem solid rgba(var(--color--primary-rgb), 0.2);
                visibility: visible;
                transition: var(--transition);

                &:empty {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }

        .filter__header__clear {
            border: none;
            background: var(--color--grey-xlight);
            font-size: 0.75rem;
            color: var(--color--grey-dark);
            padding: 0.75rem;
            border-radius: 0.25rem;
            cursor: pointer;
            transition: var(--transition);
            font-weight: 500;
            height: 2.5rem;
            display: flex;
            align-items: center;

            &:hover,
            &:focus-visible {
                background-color: var(--color--grey-light);
                color: var(--color--primary-dark);
            }
        }

        .filter__header__close {
            border: none;
            background: var(--color--grey-xlight);
            color: var(--color--grey-dark);
            border-radius: 0.25rem;
            cursor: pointer;
            transition: var(--transition);
            height: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 1.25rem;
            }

            &:hover,
            &:focus-visible {
                background-color: var(--color--grey-light);
                color: var(--color--primary-dark);
            }
            
            @media screen and (min-width: 1024px) {
                display: none;
            }
        }

        @media screen and (max-width: 1023px)  {
            display: grid;
            grid-template-columns: minmax(0, 1fr) auto 2.5rem;
            padding: 1rem;
        }
    }

    .filter__body {
        overflow: auto;
    }

    .filter__footer {
        border-top: 0.0625rem solid var(--color--grey-light);
        background-color: var(--color--grey-xlight);
        
        .filter__footer__theme {
            display: none;
        }

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1.5rem;
            padding: 1rem;
        }

        p {
            display: none;
            margin: 0;
            font-size: 0.875rem;
            color: var(--color--grey-dark);
        }

        .filter-switch-map {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            width: 8rem;
            height: 2.5rem;
            border: 0.0625rem solid var(--color--grey-light);
            border-radius: 2.5rem;
            overflow: hidden;
            background-color: var(--color--white);
        }

        .button-switch {
            position: relative;
            background-color: var(--color--white);
            border: none;
            border-radius: 2.5rem;
            transition: var(--transition);
            cursor: pointer;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: var(--color--primary);
                z-index: 0;
                border-radius: 2.5rem;
                transition: var(--transition);
            }

            i {
                position: relative;
                z-index: 1;
            }

            &#map-switch-globe {
                &::before {
                    transform: translateX(100%);
                }
            }

            &#map-switch-mercator {
                &::before {
                    transform: translateX(-100%);
                }
            }

            &.-active {
                color: var(--color--white);

                &#map-switch-globe,
                &#map-switch-mercator {
                    &::before {
                        transform: translateX(0);
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1023px) {
        right: 1rem;
        height: calc(100vh - 8rem);
        top: calc(50% - 3rem);
        width: clamp(15rem, calc(100% - 1rem), 18rem);

        &.-active {
        }

        &:not(.-active) {
            display: none;
        }
    }

    @media screen and (min-width: 1024px) {
        left: 2rem;
        width: 18rem;
        height: calc(100vh - var(--header-height) - 4rem);
        max-height: 38rem;
    }
}

.filter__item.-tourism,
.filter__item.-price {
    display: none;
}

.loader {
    position: absolute;
    top: 4rem;
    left: calc(50% - 10rem);
    width: 20rem;
    z-index: 99;
    transition: opacity 0.5s ease-out;

    .loader__bar {
        width: 100%;
        height: 0.25rem;
        border-radius: 0.25rem;
        background-color: var(--color--white);
    }
    .loader__progress {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        border-radius: 0.25rem;
        background-color: var(--color--primary);
        transition: var(--transition-slow);
        box-shadow: 0 0 1rem 0 var(--color--primary);
    }
}

.loader.hidden {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
}

.loader p {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.hp__hero {
    position: relative;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    padding-bottom: 25vh;

    .hp__hero__title {
        margin: 0;
        font-size: clamp(2rem, 4dvw, 4.5rem);
        color: var(--color--white);
        text-transform: uppercase;
        font-weight: 800;
    }

    .hp__hero__subtitle {
        margin: 0;
        font-size: clamp(1rem, 1.5dvw, 1.25rem);
        color: var(--color--white);

        strong {
            color: inherit;
        }
    }

    .hp__hero__actions {
        margin-top: 0.5rem;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1rem;
        width: 100%;
        max-width: 23rem;

        @media screen and (min-width: 1024px) {
            gap: 2rem;
        }
    }

    .hp__hero__select {
        height: 3.5rem;
        padding: 1rem;
        border-radius: 0.25rem;
        border: none;
        width: 100%;
        background-color: var(--color--white);
        font-weight: 600;
    }

    .hp__hero__btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 3.5rem;
        padding: 0 1rem;
        border-radius: 0.25rem;
        border: 0.0625rem solid var(--color--white);
        width: 100%;
        background-color: transparent;
        text-decoration: none;
        font-weight: 800;
        color: var(--color--white);
        transition: var(--transition);

        i {
            font-size: 1.125rem;
        }

        &:hover,
        &:focus-visible {
            background-color: var(--color--white);
            color: var(--color--primary-dark);
            box-shadow: var(--shadow-small);

            i {
                color: var(--color--primary);
            }
        }
    }
}

.hp__map {
    position: relative;
    margin-top: -25vh;
    overflow: hidden;
    width: calc(100% + 2rem);
    left: -1rem;

    @media screen and (min-width: 1024px) {
        left: -2rem;
        width: calc(100% + 4rem);
    }
}

.hp__search {
    position: relative;
    z-index: 50;
    width: 100dvw;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    background: var(--color--weather);
    box-shadow: var(--shadow-small);
    padding: 1.5rem;

    h2,
    p {
        margin: 0;
    }

    h2 {
        font-size: 1.25rem;
    }

    p {
        font-size: 0.875rem;
    }
}

.maplibregl-cooperative-gesture-screen {
    background: none !important;

    .maplibregl-desktop-message,
    .maplibregl-mobile-message {
        position: relative;
        padding: 1rem;
        border-radius: 0.25rem;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--color--primary-dark);
            opacity: 0.75;
            z-index: -1;
        }
    }
}

.gradient__container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0.7;

    .gradient {
        display: block;
        position: absolute;
        width: 200%;
        height: 200%;
        background: radial-gradient(var(--color--primary), transparent 50%);

        &.-first {
            --color--primary: #562aaa;

            top: -80%;
            left: -100%;
        }

        &.-second {
            --color--primary: #21FCC7;

            top: -120%;
            right: -120%;
        }

        &.-third {
            --color--primary: #2B1F70;

            width: 200%;
            height: 200%;
            top: 0;
            right: -100%;
            opacity: 0.6;
        }
    }
}

#hp__map__center {
    position: absolute;
    top: 50%;
    left: 0;
    height: 100vh;
    width: 0;
    transform: translateY(-50%);
}

.top-countries__overlay,
.filter-mobile__overlay {
    background: linear-gradient(90deg, rgba(0,0,0, 0.5) 0%, rgba(0,0,0, 1) 100%);
    opacity: 0;
    visibility: hidden;
    transition-delay: 0s;
    transition-duration: var(--transition-slow);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
    z-index: 2;

    &.-active {
        visibility: visible;
        opacity: 0.2;
    }

    @media screen and (min-width: 1024px) {
        display: none;
    }
}
